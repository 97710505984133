import React from "react";
import { Link } from "gatsby";

export default function Tabcontent({ media, cat, info, x }) {
  return (
    <Link to={"fertile-edge-magazine/" + x.slug}>
      <div className="f_contnet">
        <img src={media} alt="blog cover image" />
        <h5>{cat}</h5>
        <p>{info}</p>
      </div>
    </Link>
  );
}
