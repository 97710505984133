import React, { useRef, useEffect } from "react";
import { Col } from "react-bootstrap";
import Editors from "./editorsPick.js";
import add1 from "./assets/images/add1.png";
import add2 from "./assets/images/add2.png";
import add1logo from "./assets/images/add1logo.svg";
import add2logo from "./assets/images/add2ogo.png";
import user from "./assets/images/user.png";

import { Link, navigateTo } from "gatsby";

export default function Reads({ tagline, content, reads }) {
  const staticimgad = useRef();
  console.log("CONTENT IN READS", content);
  useEffect(() => { }, []);

  const onClickDetail = (x) => {
    navigateTo(`fertile-edge-magazine/`);
  };

  const renderRead = (read) => {
    console.log(read);
    return (
      <div className="morebox">
        <Col md={12} lg={8}>
          <Link to={"fertile-edge-magazine/" + read.slug}>
            <div onClick={() => onClickDetail(read)}>
              <img src={read.media} alt="blog cover image" />
              <div className="more">
                <div>
                  <h4>{read.category}</h4>
                  <p>{read.title}</p>
                </div>
                <div className="user">
                  <h5>{`By ${read.user.name}`}</h5>
                </div>
              </div>
            </div>
          </Link>
        </Col>
      </div>
    );
  };

  return (
    <div className="Reads">
      <div className="position-relative check">
        {reads
          .slice(0, 8)
          .filter((x) => x.category.toString().toLowerCase() != "featured")
          .map(renderRead)}

        <div className="sticky-feature">
          <Col md={12} lg={4}>
            <div className="" ref={staticimgad}>
              <div className="addblock addfirst">
                <h3>Fertility Wellness Kit</h3>
                <img className="main-img" src={add1} />
                <a
                  href="https://www.fertilityanswers.io/genetics"
                  target="_blank"
                >
                  Learn More
                </a>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <Editors tagline={tagline} content={content} />
      <div className="position-relative check">
        {reads.slice(8).map(renderRead)}

        <div className="sticky-feature">
          <Col md={12} lg={4}>
            <div className="" ref={staticimgad}>
              <div className="addblock addsecond">
                <img className="logo-img" src={add2logo} />
                <img className="main-img" src={add2} />
                <p>
                  Life As Imagined is a fully-qualified Virtual Weight
                  Management and Wellness Company, dedicated to helping people
                  lose weight, the right way.{" "}
                </p>
                <a href="https://lifeasimagined.com/" target="_blank">
                  Learn More
                </a>
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  );
}
